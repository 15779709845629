import './Gallery.css';

export function Gallery() {
    return (
        <div className="gallery-container">
            <h2 className='text-center'>OUR SCHOOL GALLERY</h2>
            <div className='gallery'>
                <img className='g-img' src="g-2.jpg" alt="school pic" />
                <img className='g-img' src="g-3.jpg" alt="school pic" />
                <img className='g-img' src="g-4.jpg" alt="school pic" />
                <img className='g-img' src="g-5.jpg" alt="school pic" />
                <img className='g-img' src="g-6.jpg" alt="school pic" />
                <img className='g-img' src="g-7.jpg" alt="school pic" />
                <img className='g-img' src="g-11.jpg" alt="school pic" />
                <img className='g-img' src="g-12.jpg" alt="school pic" />
                <img className='g-img' src="g-13.jpg" alt="school pic" />
                <img className='g-img' src="g-14.jpg" alt="school pic" />
                <img className='g-img' src="g-15.jpg" alt="school pic" />
                <img className='g-img' src="g-16.jpg" alt="school pic" />
                <img className='g-img' src="g-17.jpg" alt="school pic" />
                <img className='g-img' src="g-18.jpg" alt="school pic" />
                <img className='g-img' src="g-19.jpg" alt="school pic" />
                <img className='g-img' src="g-20.jpg" alt="school pic" />
                <img className='g-img' src="g-21.jpg" alt="school pic" />
                <img className='g-img' src="g-22.jpg" alt="school pic" />
            </div>
        </div>
    );
}
