import { useState } from 'react';
import './Navbar.css';

export function Navbar() {

    const [isPaused, setIsPaused] = useState(false);

    const handleMouseOver = () => {
        setIsPaused(true); // Pause the animation
      };
    
      const handleMouseOut = () => {
        setIsPaused(false); // Resume the animation
      };

    return (
       <div className="navbar-bg nav-style">
            <nav className='navbar navbar-expand-lg navbar-light bg-warning'>
                <div className='container-fluid'>
                    <a href="/" className="navbar-brand d-flex align-items-center">
                        <img className='logo' src="jovm-logo.png" alt="Logo" width="60" height="60" />
                        <span className='ms-2 fw-bold h1'>JAI ONKAR VIDYA MANDIR</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className='collapse navbar-collapse' id='navbarNav'>
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fw-bold">
                            <li className="nav-item me-2"> <a className='nav-link bi bi-house' href="/"> Home</a></li>
                            <li className="nav-item me-2"><a className='nav-link bi bi-person' href="/about"> About</a></li>
                            <li className="nav-item me-2"><a className='nav-link bi bi-file-image' href="/gallery"> Gallery</a></li>
                            <li className="nav-item"><a className='nav-link bi bi-box-arrow-in-right' href="/login"> Login</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="marquee-wrapper">
  <h4
    className={`marquee-content ${isPaused ? "paused" : ""}`}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}
  >
    Our School: JAI ONKAR VIDYA MANDIR - That is the root of true education.
  </h4>
</div>

        </div>
    );
}

