import React, { useEffect, useState } from 'react';
import './InvoiceTable.css';
import { getAllInvoices } from '../../api';

export function InvoiceTable() {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    // Fetch all invoices from the backend
    const fetchInvoices = async () => {
      try {
        const data = await getAllInvoices();
        setInvoices(data);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, []);

  return (
    <div className="table-container">
      <h2 className='text-center bg-warning invoice-list'>Invoice List</h2>
      <div className="table-responsive">
        <table className="table table-hover table-striped table-success text-center">
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Date</th>
              <th>Student Name</th>
              <th>Roll Number</th>
              <th>Class</th>
              <th>Section</th>
              <th>Other</th>
              <th>Total</th>
              <th>Signature</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.admission_number}>
                <td>{invoice.admission_number}</td>
                <td>{invoice.date}</td>
                <td>{invoice.student_name}</td>
                <td>{invoice.roll_no}</td>
                <td>{invoice.class_name}</td>
                <td>{invoice.section}</td>
                <td>{invoice.other}</td>
                <td>{invoice.total}</td>
                <td>{invoice.signature}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
